import React, { useState, useEffect, useRef, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Replay from '@material-ui/icons/Replay';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TextField from '../../Common/TextField';
import TextFieldPassword from '../../Common/TextFieldPassword';
import Button from '../../Common/Button';
import {
  BLUE_BUTTOM,
  WHITE,
  ORANGE,
  FONT_FAMILY,
} from '../../../constants/theme.styles';
import { login, resendConfirmation } from '../../../services/signup';
import { saveAuthorization } from '../../../utils/auth';
import Link from '../../Common/Link';
import TitleForm from '../../Common/Title';
import SubTitle from '../../Common/SubTitle';
import { errorMessagesLogin } from '../../../utils/MessageError';
import {
  APP_TITLE,
  APP_URL,
  URL_SIGNUP,
  URL_RECOVERY_PASSWORD,
  URL_CHECK_TOKEN,
  PUBLIC_SIGNUP,
} from '../../../constants/http.constants';
import { showDialog, hideDialog } from '../../../actions/general';

const useStyles = makeStyles(() => ({
  arrow: {
    fontSize: 10,
    margin: -2,
  },
}));

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const emailInputRef = useRef(null);
  const [form, setForm] = useState({
    login: '',
    password: '',
  });

  const marginTop = 0;
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState('');
  const [subTitleText, setSubTitle] = useState('NULL_');
  const [showResendToken, setShowResendToken] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!form.login || !form.password) {
      setError(true);
      setSubTitle('REQUIRED_FIELD');
      return;
    }
    try {
      setIsDisabled('login');
      setIsDisabled(true);
      setError(false);
      const resp = await login(form);

      if (resp.status === 200) {
        const { token, profiles, refresh_token: refreshToken } = resp.data;
        saveAuthorization(token, profiles, refreshToken);
        window.location.href = `${APP_URL}#!/dashboard`;
      }
    } catch (err) {
      setIsDisabled(false);
      setError(true);

      let msg = null;
      if ('messages' in err.response.data) {
        msg = errorMessagesLogin[err.response.data.messages[0]];
        setSubTitle(msg);
      }

      setShowResendToken(msg === 'NOT_CONFIRMED_USER');
    }
  }, [form]);

  const sendTokenAgain = useCallback(
    async (email) => {
      try {
        const resp = await resendConfirmation({ email });

        localStorage.setItem('tokenValidationEmail', email);
        if (resp.status === 200) history.push(URL_CHECK_TOKEN);
      } catch (err) {
        setSubTitle(errorMessagesLogin[err.response.data.messages[0]]);
        setError(true);
      }
    },
    [history]
  );

  const dialogModal = (title, description) => {
    dispatch(
      showDialog({
        title: formatMessage({
          id: title,
        }),
        text: formatMessage({
          id: description,
        }),
        firstButton: formatMessage({ id: 'NO' }),
        firstButtonClick: () => dispatch(hideDialog()),
        secondButton: formatMessage({ id: 'YES' }),
        secondButtonClick: () => {
          sendTokenAgain(form.login);
          dispatch(hideDialog());
        },
      })
    );
  };

  useEffect(() => {
    if (form.login || form.password) {
      setError(false);
      setSubTitle('NULL_');
    }
  }, [form]);

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  return (
    <form
      id="form-signin"
      onSubmit={async (e) => {
        e.preventDefault();

        onSubmit();
      }}
    >
      <TitleForm id="tit-signin" error={error}>
        {formatMessage({ id: 'LOGIN_TITLE' }, { app_title: APP_TITLE })}
      </TitleForm>
      <SubTitle id="sub-signin" error={error}>
        {subTitleText ? formatMessage({ id: subTitleText }) : null}
      </SubTitle>
      <Grid
        item
        sm={12}
        xs={12}
        style={{ marginTop: showResendToken ? 20 : 0 }}
      >
        <TextField
          inputRef={emailInputRef}
          id="inp-login"
          name="login"
          label={formatMessage({ id: 'LOGIN' })}
          style={{ marginTop }}
          type="email"
          onChange={(e) => setForm({ ...form, login: e.target.value })}
          error={error && !form.login}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TextFieldPassword
          id="inp-password"
          name="password"
          label={formatMessage({ id: 'PASSWORD' })}
          style={{ marginTop }}
          type="text"
          autoComplete="current-password"
          onChange={(e) => setForm({ ...form, password: e.target.value })}
          error={error && !form.password}
        />
      </Grid>
      <Grid container direction="row">
        <Grid item md={7} sm={7} xs={12} className={classes.link}>
          {showResendToken && form.login ? (
            <Grid item md={10} sm={10} xs={10}>
              <Button
                label={formatMessage({ id: 'SEND_AGAIN_TOKEN' })}
                id="btn-send-again-check-token"
                style={{
                  fontSize: 12,
                  padding: 10,
                  fontFamily: FONT_FAMILY,
                  backgroundColor: ORANGE,
                  color: WHITE,
                  display: 'flex',
                }}
                endIcon={
                  isDisabled && isLoading === 'resend_token' ? (
                    <CircularProgress size={15} style={{ color: WHITE }} />
                  ) : (
                    ''
                  )
                }
                disabled={isDisabled}
                startIcon={isDisabled ? null : <Replay />}
                onClick={() => {
                  setIsLoading('resend_token');
                  dialogModal(
                    'RESEND_SIG_UP_CONFIRMATION',
                    'RESEND_SIG_UP_CONFIRMATION_MESSAGE'
                  );
                }}
              />
            </Grid>
          ) : null}

          {PUBLIC_SIGNUP && (
            <Link id="link-signup" to={URL_SIGNUP} style={{ marginLeft: 10 }}>
              {formatMessage({ id: 'SIGNUP' })}
            </Link>
          )}
          <br />
          <Link
            id="lnk-forgot-password"
            to={URL_RECOVERY_PASSWORD}
            style={{ marginLeft: 10 }}
          >
            {formatMessage({ id: 'FORGOT_YOUR_PASSWORD' })}
          </Link>
        </Grid>
        <Grid item md={5} sm={5} xs={12}>
          <Button
            id="btn-submit-login"
            color="primary"
            label={formatMessage({ id: 'LOGIN' })}
            style={{
              fontSize: 12,
              padding: 10,
              backgroundColor: BLUE_BUTTOM,
              color: WHITE,
            }}
            startIcon=""
            endIcon={
              isDisabled && isLoading === 'login' ? (
                <CircularProgress size={15} style={{ color: WHITE }} />
              ) : (
                ''
              )
            }
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </form>
  );
}
