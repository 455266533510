/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import CheckboxElement2 from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import { BLUE_BUTTOM, RED_ERROR } from '../../constants/theme.styles';

const CheckboxElement = withStyles({
  root: {
    '& .Mui-checked': {
      color: BLUE_BUTTOM,
    },
  },
  colorSecondary: {
    color: RED_ERROR,
  },
})(CheckboxElement2);

export default function Checkbox({ checked, onChange, value, style, error }) {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <CheckboxElement
      checked={checked}
      onChange={handleChange}
      value={value}
      style={style}
      color={error ? 'secondary' : 'default'}
    />
  );
}

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.string.isRequired,
  style: PropTypes.object,
  error: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
};
