import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl, FormattedMessage } from 'react-intl';
import CookieIconSVG from '../imgs/cookie_black.svg';
import { RED_ERROR, GREY_SUBTITLE } from '../constants/theme.styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    // maxWidth: '100%'
  },
  content: {
    textAlign: 'center',
    color: RED_ERROR,
    paddingBottom: 20,
    paddingTop: 20,
    maxWidth: 700,
  },
  footer: {
    color: GREY_SUBTITLE,
  },
}));

function WarningCheckThirdPartyCookies() {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  return (
    <div className={classes.container}>
      <div>
        <img width={150} src={CookieIconSVG} alt="Cookie" />
      </div>
      <div className={classes.content}>
        <h3>{formatMessage({ id: 'THIRD_PARTY_COOKIE_TITLE' })}</h3>
        <FormattedMessage id="THIRD_PARTY_COOKIE_SUBTITLE" />
      </div>
      <footer className={classes.footer}>
        <span>{formatMessage({ id: 'THIRD_PARTY_COOKIE_HELPER' })}</span>
      </footer>
    </div>
  );
}

function ThirdPartyAccessRoute({ component: Component, ...rest }) {
  const checkThirdPartyCookiesAccess = () => {
    try {
      window.localStorage.getItem('TEST');
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        checkThirdPartyCookiesAccess() ? (
          <Component {...props} />
        ) : (
          <WarningCheckThirdPartyCookies />
        )
      }
    />
  );
}

ThirdPartyAccessRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ThirdPartyAccessRoute;
