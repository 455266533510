/* eslint-disable no-underscore-dangle */
let _WHITE = '#FFFFFF';
let _GREY_TITLE = '#263035';
let _GREY_SUBTITLE = '#808080';
let _GREY_LIGHT = '#CBCBCB';
let _GREY_NUMBER = '#979EA5';
let _YELLOW = '#FFCA01';
let _BLUE_LINK = '#888892';
let _BLUE_BUTTOM = '#0E74CC';
let _BLUE_BG = '#0E74CC';
let _RED_ERROR = '#FF4B36';
let _BLUE_LIGHT = '#1BDCF5';
let _ORANGE = '#e8822c';
let _GREEN_LIGHT = '#76ff03';
let _DISABLED = '#00000042';
let _VIDEO_CONFERENCE_COLOR = [
  '#b71c1c',
  '#ad1457',
  '#6a1b9a',
  '#4527a0',
  '#283593',
  '#0d47a1',
  '#01579b',
  '#006064',
  '#00695c',
  '#1b5e20',
  '#33691e',
  '#827717',
  '#f57f17',
  '#ff6f00',
  '#e65100',
  '#bf360c',
  '#3e2723',
  '#424242',
  '#37474f',
];
let _FONT_FAMILY = 'Poppins';
let _BORDER_RADIUS = 40;
let _BORDER_RADIUS_MEDIUM = 10;
let _FOOTER_BG = '#F5F9FF';
let _FOOTER_COLOR = '#888892';

if (process.env.REACT_APP_THEME === 'zimbra') {
  _WHITE = '#FFFFFF';
  _GREY_TITLE = '#263035';
  _GREY_SUBTITLE = '#808080';
  _GREY_LIGHT = '#F2F2F2';
  _GREY_NUMBER = '#979EA5';
  _YELLOW = '#FFCA01';
  _BLUE_LINK = '#0088c1';
  _BLUE_BUTTOM = '#0088c1';
  _BLUE_BG = '#0088c1';
  _RED_ERROR = '#FF4B36';
  _BLUE_LIGHT = '#1BDCF5';
  _ORANGE = '#e8822c';
  _GREEN_LIGHT = '#76ff03';
  _DISABLED = '#00000042';
  _VIDEO_CONFERENCE_COLOR = [
    '#b71c1c',
    '#ad1457',
    '#6a1b9a',
    '#4527a0',
    '#283593',
    '#0d47a1',
    '#01579b',
    '#006064',
    '#00695c',
    '#1b5e20',
    '#33691e',
    '#827717',
    '#f57f17',
    '#ff6f00',
    '#e65100',
    '#bf360c',
    '#3e2723',
    '#424242',
    '#37474f',
  ];
  _FONT_FAMILY = `'Helvetica Neue', Helvetica, Arial, sans-serif`;
  _BORDER_RADIUS = 2;
  _BORDER_RADIUS_MEDIUM = 10;
  _FOOTER_BG = '#0088c1';
  _FOOTER_COLOR = '#FFFFFF';
}

export const WHITE = _WHITE;
export const GREY_TITLE = _GREY_TITLE;
export const GREY_SUBTITLE = _GREY_SUBTITLE;
export const GREY_LIGHT = _GREY_LIGHT;
export const GREY_NUMBER = _GREY_NUMBER;
export const YELLOW = _YELLOW;
export const BLUE_LINK = _BLUE_LINK;
export const BLUE_BUTTOM = _BLUE_BUTTOM;
export const RED_ERROR = _RED_ERROR;
export const BLUE_LIGHT = _BLUE_LIGHT;
export const BLUE_BG = _BLUE_BG;
export const ORANGE = _ORANGE;
export const GREEN_LIGHT = _GREEN_LIGHT;
export const DISABLED = _DISABLED;
export const VIDEO_CONFERENCE_COLOR = _VIDEO_CONFERENCE_COLOR;
export const FONT_FAMILY = _FONT_FAMILY;
export const BORDER_RADIUS = _BORDER_RADIUS;
export const BORDER_RADIUS_MEDIUM = _BORDER_RADIUS_MEDIUM;
export const FOOTER_BG = _FOOTER_BG;
export const FOOTER_COLOR = _FOOTER_COLOR;

export const generateColor = () =>
  VIDEO_CONFERENCE_COLOR[
    Math.floor(Math.random() * VIDEO_CONFERENCE_COLOR.length)
  ];
