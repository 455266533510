/* eslint-disable react/require-default-props */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';

import { BLUE_BUTTOM, BORDER_RADIUS } from '../../constants/theme.styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: BORDER_RADIUS,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: BLUE_BUTTOM,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: BLUE_BUTTOM,
    },
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
}));

export default function TextFieldPassword({
  label,
  error,
  onChange,
  onBlur,
  required,
  style,
  inputRef,
  autoComplete,
  id,
  labelWidth,
}) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (onChange) {
      onChange(event);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <div>
        <FormControl
          className={clsx(classes.margin, classes.textField)}
          variant="outlined"
          error={error}
          required={required}
          style={style}
        >
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            error={error}
            required={required}
            id={id}
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            onBlur={onBlur}
            inputRef={inputRef}
            autoComplete={autoComplete}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  id={`${id}-icon-visibility`}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={labelWidth}
          />
        </FormControl>
      </div>
    </div>
  );
}

TextFieldPassword.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  style: PropTypes.object,
  inputRef: PropTypes.object,
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  labelWidth: PropTypes.number,
  onBlur: PropTypes.func,
};

TextFieldPassword.defaultProps = {
  labelWidth: 70,
};
