/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  createRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import log from 'utils/log';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Replay from '@material-ui/icons/Replay';

import { Grid } from '@material-ui/core';

import TextFieldMin from '../../Common/TextFieldMin';
import Button from '../../Common/Button';
import TitleForm from '../../Common/Title';
import SubTitle from '../../Common/SubTitle';

import {
  CHECK_TOKEN_RECOVERY,
  CHECK_TOKEN,
  URL_UPDATE_PASSWORD,
  URL_WELCOME,
  URL_CHECK_TOKEN_RECOVERY,
} from '../../../constants/http.constants';
import { actions } from '../action';
import {
  confirmUserToken,
  confirmPasswordToken,
  resendConfirmation,
  forgotPassword,
} from '../../../services/signup';
import {
  BLUE_BUTTOM,
  WHITE,
  BLUE_LIGHT,
  ORANGE,
  FONT_FAMILY,
} from '../../../constants/theme.styles';
import { saveAuthorization } from '../../../utils/auth';
import { errorMessagesCheckPassword } from '../../../utils/MessageError';
import { showDialog, hideDialog } from '../../../actions/general';

const prevUrls = {
  'check-token': '/user/signup',
  'check-token-recovery': '/user/recovery-password',
};

export default function CheckYourEmail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { componentUrl } = useParams();
  const { formatMessage } = useIntl();

  const [isDisabled, setIsDisabled] = useState(false);
  const [tokenOld, setTokenOld] = useState('');
  const [titleText, setTitle] = useState('');
  const [subTitleText, setSubTitle] = useState([]);
  const [token, setToken] = useState(Array(6).fill(''));
  const [error, setError] = useState(false);
  const [index, setIndex] = useState(0);
  const [interval, setInt] = useState(null);
  const [email, setEmail] = useState(
    localStorage.getItem('tokenValidationEmail')
  );

  const elRef = useRef([...Array(6)].map(() => createRef()));
  const btnRef = useRef(null);

  const resetToken = useCallback(() => {
    setIndex(0);
    setError(false);
    setToken(Array(6).fill(''));
    elRef.current[0].current.focus();
  }, []);

  const messageError = useCallback(
    (err) => {
      setError(true);
      setIsDisabled(false);
      elRef.current[5].current.blur();

      if (err.response) {
        let msg = '';

        if ('messages' in err.response.data) {
          msg = errorMessagesCheckPassword[err.response.data.messages[0]];
          setSubTitle([msg]);
        } else {
          msg = errorMessagesCheckPassword[err.response.data.message];
          setSubTitle([msg]);
        }
        return msg;
      }
      return '';
    },
    [dispatch]
  );

  const senAgainToken = async () => {
    try {
      if (componentUrl === CHECK_TOKEN) {
        await resendConfirmation({ email });
      } else {
        const resp = await forgotPassword({ email });
        localStorage.setItem('tokenValidationEmail', email);

        if (resp.status === 200) history.push(URL_CHECK_TOKEN_RECOVERY);
      }
    } catch (err) {
      log.logger(err);
      messageError(err);
    }
  };

  const setModule = useCallback(() => {
    let str;
    let sub;
    switch (componentUrl) {
      case CHECK_TOKEN_RECOVERY:
        setTitle('TITLE_RECOVERY_PASS_TOKEN');
        setSubTitle(['SUBTITLE_RECOVERY_PASS_TOKEN']);
        break;
      case CHECK_TOKEN:
        setTitle('CHECK_YOUR_EMAIL_TITLE');

        str = formatMessage({ id: 'CHECK_YOUR_EMAIL_SUBTITLE' });
        sub = str.split('email_typed_by_user');

        setSubTitle(sub);
        break;
      default:
    }
  }, [titleText, setSubTitle]);

  const dialogModal = (title, description) => {
    dispatch(
      showDialog({
        title: formatMessage({
          id: title,
        }),
        text: formatMessage({
          id: description,
        }),
        firstButton: formatMessage({ id: 'NO' }),
        firstButtonClick: () => dispatch(hideDialog()),
        secondButton: formatMessage({ id: 'YES' }),
        secondButtonClick: () => {
          senAgainToken(email);
          dispatch(hideDialog());
          setError(false);
          setModule();
          resetToken();
        },
      })
    );
  };

  const confirmToken = useCallback(
    async (newToken) => {
      if (newToken.join('') !== tokenOld) {
        setIsDisabled(true);

        const sendConfirmToken = async (requestFunction, redirectUrl) => {
          try {
            const response = await requestFunction({
              token: newToken.join(''),
              email,
            });

            if (response.status === 200) {
              const { token, profiles } = response.data;
              saveAuthorization(token, profiles);

              history.push(redirectUrl);
            }
          } catch (err) {
            log.logger(err);
            resetToken();

            if (messageError(err) === 'MAX_NUMBER_OF_TRIES_EXCEEDED') {
              dialogModal(
                'MAX_NUMBER_OF_TRIES_EXCEEDED_MODAL',
                'WOULD_YOU_LIKE_TO_RECEIVE_CONFIRMATION_TOKEN_AGAIN'
              );
            }
          }
        };

        switch (componentUrl) {
          case CHECK_TOKEN_RECOVERY:
            sendConfirmToken(confirmPasswordToken, URL_UPDATE_PASSWORD);
            break;
          case CHECK_TOKEN:
            sendConfirmToken(confirmUserToken, URL_WELCOME);
            break;
          default:
        }
      }
    },
    [componentUrl, email, history, tokenOld, messageError]
  );

  const handleOnChange = useCallback(
    (e, key) => {
      const value = parseInt(e.target.value, 10);
      const newToken = [...token];

      if (Number.isInteger(value)) {
        newToken[key] = `${value}`;
        setToken(newToken);
        setIndex(key + 1);
        if (elRef.current[key + 1]) elRef.current[key + 1].current.focus();

        const filledPosition = newToken.filter((t) => t);
        if (filledPosition.length === 6) {
          confirmToken(filledPosition);
          setTokenOld(filledPosition.join(''));
          setIsDisabled(false);
        } else {
          setIsDisabled(false);
        }
      } else {
        newToken[key] = '';
        setToken(newToken);
      }
    },
    [token, elRef, confirmToken, index]
  );

  const goBack = () => {
    setError(false);

    if (index === 0) {
      history.goBack();
      return;
    }

    setModule();
    setIndex(index - 1);

    const newToken = [...token];
    newToken[index - 1] = '';
    setToken(newToken);
    if (elRef.current[index - 1]) elRef.current[index - 1].current.focus();
  };

  const checkEmailAvailable = useCallback(() => {
    const tokenValidationEmail = localStorage.getItem('tokenValidationEmail');
    if (!tokenValidationEmail) {
      // Could be better idea pass this info from the previous page
      history.push(prevUrls[componentUrl]);
      return false;
    }

    setEmail(tokenValidationEmail);
    return true;
  }, [componentUrl, history]);

  useEffect(() => {
    checkEmailAvailable();
    dispatch(actions.step(1));
    setInt(
      setInterval(() => {
        if (elRef.current[0].current) {
          elRef.current[0].current.focus();
        }
      }, 1000)
    );
  }, [elRef.current, checkEmailAvailable]);

  useEffect(() => {
    setModule();
  }, [componentUrl, formatMessage]);

  return (
    <Grid container>
      <Grid item sm={12} xs={12}>
        <TitleForm error={error} id="tit-check-token">
          {titleText ? formatMessage({ id: titleText }) : null}
        </TitleForm>
      </Grid>
      <Grid item sm={12} xs={12}>
        {subTitleText.length > 1 && !error ? (
          <SubTitle error={error} id="sub-check-token">
            {subTitleText[0]}
            <span style={{ color: BLUE_LIGHT }}>{email}</span>
            {subTitleText[1]}
          </SubTitle>
        ) : null}
        {subTitleText.length === 1 ? (
          <SubTitle error={error}>
            {formatMessage({ id: subTitleText[0] })}
          </SubTitle>
        ) : null}
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ marginTop: 25, marginBottom: 25 }}
      >
        <Grid item sm={1} xs={1} style={{ marginRight: 15 }}>
          <TextFieldMin
            id="inpu-0-check-token"
            style={{ marginTop: 0 }}
            type="text"
            inputRef={elRef.current[0]}
            onClick={() => setIndex(1)}
            onChange={(e) => handleOnChange(e, 0)}
            error={error}
            value={token[0]}
            disabled={isDisabled}
            onBlur={() => window.clearInterval(interval)}
          />
        </Grid>
        <Grid item sm={1} xs={1} style={{ marginRight: 15 }}>
          <TextFieldMin
            style={{ marginTop: 0 }}
            type="text"
            id="inpu-1-check-token"
            inputRef={elRef.current[1]}
            onClick={() => setIndex(2)}
            onChange={(e) => handleOnChange(e, 1)}
            error={error}
            value={token[1]}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={1} xs={1} style={{ marginRight: 15 }}>
          <TextFieldMin
            style={{ marginTop: 0 }}
            type="text"
            id="inpu-2-check-token"
            inputRef={elRef.current[2]}
            onClick={() => setIndex(3)}
            onChange={(e) => handleOnChange(e, 2)}
            error={error}
            value={token[2]}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={1} xs={1} style={{ marginRight: 15 }}>
          <TextFieldMin
            style={{ marginTop: 0 }}
            type="text"
            id="inpu-3-check-token"
            inputRef={elRef.current[3]}
            onClick={() => setIndex(4)}
            onChange={(e) => handleOnChange(e, 3)}
            error={error}
            value={token[3]}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={1} xs={1} style={{ marginRight: 15 }}>
          <TextFieldMin
            style={{ marginTop: 0 }}
            type="text"
            id="inpu-4-check-token"
            inputRef={elRef.current[4]}
            onClick={() => setIndex(5)}
            onChange={(e) => handleOnChange(e, 4)}
            error={error}
            value={token[4]}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item sm={1} xs={1} style={{ marginRight: 15 }}>
          <TextFieldMin
            style={{ marginTop: 0 }}
            type="text"
            id="inpu-5-check-token"
            inputRef={elRef.current[5]}
            onClick={() => setIndex(6)}
            onChange={(e) => handleOnChange(e, 5)}
            error={error}
            value={token[5]}
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item md={4} sm={4} xs={4} ref={btnRef}>
          <Button
            label={formatMessage({ id: 'BACK' })}
            id="btn-back-check-token"
            style={{
              fontSize: 12,
              padding: 10,
              fontFamily: FONT_FAMILY,
              backgroundColor: BLUE_BUTTOM,
              color: WHITE,
              display: 'flex',
            }}
            endIcon={
              isDisabled ? (
                <CircularProgress size={15} style={{ color: WHITE }} />
              ) : (
                ''
              )
            }
            disabled={isDisabled}
            startIcon={isDisabled ? null : <ArrowBackIcon />}
            onClick={() => goBack()}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <Button
            label={formatMessage({ id: 'SEND_AGAIN_TOKEN' })}
            id="btn-send-again-check-token"
            style={{
              fontSize: 12,
              padding: 10,
              fontFamily: FONT_FAMILY,
              backgroundColor: ORANGE,
              color: WHITE,
              display: 'flex',
            }}
            endIcon={
              isDisabled ? (
                <CircularProgress size={15} style={{ color: WHITE }} />
              ) : (
                ''
              )
            }
            disabled={isDisabled}
            startIcon={isDisabled ? null : <Replay />}
            onClick={() => {
              dialogModal(
                componentUrl === CHECK_TOKEN
                  ? 'RESEND_SIG_UP_CONFIRMATION'
                  : 'RESEND_PASSWORD_RECOVERY_TOKEN',
                componentUrl === CHECK_TOKEN
                  ? 'RESEND_SIG_UP_CONFIRMATION_MESSAGE'
                  : 'RESEND_PASSWORD_RECOVERY_TOKEN_DESCRIPTION'
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
