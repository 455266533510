import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { decodeToken } from 'react-jwt';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import log from 'utils/log';

const verifyExpToken = (token) => {
  // const decodedToken = jwt.decode(token, { complete: true });
  const decodedToken = decodeToken(token);
  log.warn({ decodedToken });
  const dateNow = new Date();

  if (decodedToken.exp < dateNow.getTime()) return true;

  return false;
};

function Auth({ location, history }) {
  const [authError, setAuthError] = useState(false);

  useEffect(() => {
    // const params = new URLSearchParams(window.location.search);
    const { from } = location.state;
    const { search } = from;
    const params = queryString.parse(search);

    if (params.token && !verifyExpToken(params.token)) {
      localStorage.setItem('authorization', params.token);
      localStorage.setItem('refresh_token', params.refresh_token);

      if (!localStorage.getItem('acting_profile')) {
        const userData = decodeToken(params.token);
        localStorage.setItem(
          'acting_profile',
          JSON.stringify({ _id: userData.user.profile_id })
        );
      }

      delete params.token;
      delete params.refresh_token;
      const nextSearch = Object.keys(params).reduce(
        (acc, key) => `${acc}${key}=${params[key]}&`,
        '?'
      );

      history.push({ ...from, search: nextSearch });
    } else {
      setAuthError(true);
    }
  }, [history, location.state]);

  return (
    <h1 style={{ color: '#FFF' }}>
      {authError ? (
        <span>
          <FormattedMessage id="ERROR_COULD_NOT_AUTHENTICATEC" />
        </span>
      ) : (
        <span>
          <FormattedMessage id="CHECKING_FOR_AUTHORIZATION" />
        </span>
      )}
    </h1>
  );
}

Auth.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Auth;
