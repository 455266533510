import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { getFileValidatingShareLink } from 'services/fileService';
import Button from '../Common/Button';
import { BLUE_BUTTOM, WHITE, FONT_FAMILY } from '../../constants/theme.styles';

import MainFrame from '../MainFrame';
import LinearProgressWithLabel from '../Common/LinearProgressWithLabel';
import TitleForm from '../Common/Title';
import SubTitle from '../Common/SubTitle';
import { API_URL } from '../../constants/http.constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 5),
    textAlign: 'center',
  },
  main: {
    maxWidth: 600,
  },
}));

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let timer;

export default function FileDownload() {
  const query = useQuery();
  const shareKey = query.get('share_key');
  const { fileId } = useParams();
  const { formatMessage } = useIntl();
  const [showProgress, setShowProgress] = useState(false);
  const [showButtonDownloadFile, setShowButtonDownloadFile] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const [title, setTitle] = useState(
    formatMessage({ id: 'FILE_DOWNLOAD_LINK_PROCESSING_TITLE' })
  );
  const [subTitle, setSubTitle] = useState(
    formatMessage({ id: 'FILE_DOWNLOAD_LINK_PROCESSING_TEXT' })
  );
  const buttonDownload = useRef(null);

  useEffect(() => {
    setShowProgress(true);
    timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 10);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress === 30) {
      (async () => {
        try {
          const res = await getFileValidatingShareLink({ fileId, shareKey });

          if (res.status === 200) {
            clearInterval(timer);
            setShowProgress(false);
            setShowButtonDownloadFile(true);
            setTitle(formatMessage({ id: 'SHARE_LINK_VALIDATED_TITLE' }));
            setSubTitle(formatMessage({ id: 'SHARE_LINK_VALIDATED_TEXT' }));

            setTimeout(() => {
              buttonDownload.current.click();
            }, 1000);
          }
        } catch (err) {
          const { data } = err.response;
          setError(true);
          clearInterval(timer);
          setShowProgress(false);

          const errorMessages = data.error_code
            ? { title: data.error_code, subTitle: `${data.error_code}_TEXT` }
            : {
                title: 'ERR_FILE_DOWNLOAD_LINK_DEFAULT',
                subTitle: 'ERR_FILE_DOWNLOAD_LINK_DEFAULT_TEXT',
              };

          setTitle(formatMessage({ id: errorMessages.title }));
          setSubTitle(formatMessage({ id: errorMessages.subTitle }));
        }
      })();
    }
  }, [progress, fileId, buttonDownload, shareKey, formatMessage]);

  const classes = useStyles();

  return (
    <MainFrame id="file-download">
      <Container component="main" className={classes.main}>
        <Paper className={classes.paper}>
          <br />
          <TitleForm
            error={error}
            id="tit-guest"
            textAlign="center"
            whiteSpace="pre-line"
          >
            {title}
          </TitleForm>
          <SubTitle error={error} id="sub-guest" textAlign="center">
            {subTitle}
          </SubTitle>
          <br />
          {showButtonDownloadFile && (
            <Button
              ref={buttonDownload}
              id="btn-submit-login"
              href={`${API_URL}/file/${fileId}/download_link?share_key=${shareKey}&force_download=true`}
              color="primary"
              label={formatMessage({
                id: 'SHARE_LINK_LABEL_BUTTON_FORCE_DOWNLOAD',
              })}
              style={{
                fontSize: 12,
                padding: 10,
                fontFamily: FONT_FAMILY,
                backgroundColor: BLUE_BUTTOM,
                color: WHITE,
              }}
              startIcon={<GetAppRoundedIcon fontSize="inherit" />}
            />
          )}
          {showProgress && <LinearProgressWithLabel value={progress} />}
        </Paper>
      </Container>
    </MainFrame>
  );
}
