import React, { useState, useEffect } from 'react';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import HomologBrowser from '../HomologBrowser';
import { FOOTER_BG, WHITE, FOOTER_COLOR } from '../../constants/theme.styles';
import { APP_TITLE, THEME } from 'constants/http.constants';

const useStyles = makeStyles(() => ({
  footer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: FOOTER_BG,
    color: FOOTER_COLOR,
    fontSize: 12,
    padding: '15px 0 15px',
    userSelect: 'none',
  },
  link: {
    color: WHITE,
    padding: '10px',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const [year, setYear] = useState('');

  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, [year]);

  return (
    <div className={classes.footer}>
      {THEME !== 'zimbra' && (
        <span>
          ©{year}{' '}
          <FormattedMessage id="COPY_RIGHT" values={{ app_title: APP_TITLE }} />{' '}
        </span>
      )}

      {THEME === 'zimbra' && (
        <span>
          {`Copyright © ${year} Synacor, Inc. All rights reserved.`}
          <br />
          <br />
          <Link
            className={classes.link}
            href="https://www.zimbra.com/legal-information/"
            target="_blank"
          >
            Legal Information
          </Link>
          |
          <Link
            className={classes.link}
            href="https://synacor.com/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
          |
          <Link
            className={classes.link}
            href="https://synacor.com/do-not-sell"
            target="_blank"
          >
            Do Not Sell My Personal Information
          </Link>
          |
          <Link
            className={classes.link}
            href="https://synacor.com/personal-data"
            target="_blank"
          >
            CCPA Disclosures
          </Link>
          <br />
          <HomologBrowser />
        </span>
      )}
    </div>
  );
}
