/* eslint-disable react/require-default-props */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  GREY_TITLE,
  RED_ERROR,
  FONT_FAMILY,
} from '../../constants/theme.styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: GREY_TITLE,
    fontFamily: FONT_FAMILY,
    fontWeight: 700,
    display: 'grid',
    justifyContent: 'flex-start',

    [theme.breakpoints.down('md')]: {
      whiteSpace: 'pre-line',
    },
  },
}));

export default function TitleForm({
  error,
  children,
  id,
  textAlign = 'start',
  fontSize = 20,
}) {
  const classes = useStyles();

  return (
    <div
      id={id}
      className={classes.title}
      style={{ color: error ? RED_ERROR : GREY_TITLE, textAlign, fontSize }}
    >
      {children}
    </div>
  );
}

TitleForm.propTypes = {
  error: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string,
  textAlign: PropTypes.string,
  fontSize: PropTypes.number,
};
