/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Footer from './Footer';

import BackgroundImage from '../../imgs/background.png';
import Link from '../Common/Link';
import { APP_URL, SHOW_LOGO, LOGO_NAME } from '../../constants/http.constants';
import { BLUE_BG } from '../../constants/theme.styles';

const rootStyle = {
  flexGrow: 1,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

if (process.env.REACT_APP_THEME === 'zimbra') {
  rootStyle.backgroundColor = BLUE_BG;
} else {
  rootStyle.backgroundImage = `url(${BackgroundImage}), linear-gradient(to bottom right, #3C1286, #0D42B2)`;
}

const useStyles = makeStyles(() => ({
  root: rootStyle,
  header: {
    minHeight: 100,
  },
  logo: {
    marginTop: '25%',
    marginBottom: '25%',
  },
  signupForm: {
    marginBottom: 70,
  },
}));

export default function MainFrame({ children, hideHeader, hideFooter }) {
  const classes = useStyles();
  const [logoName, setLogoName] = useState(null);

  useEffect(() => {
    setLogoName(LOGO_NAME);
  }, []);

  return (
    <Grid className={classes.root}>
      <Grid container direction="column" alignItems="center">
        <Grid id="logo-header" className={classes.header} item sm={12} xs={12}>
          <Link
            id="lnk-base-url-main-frame-base"
            to="#"
            onClick={() => {
              window.location.href = APP_URL;
            }}
          >
            {(SHOW_LOGO && logoName && !hideHeader && (
              <img
                src={require(`../../imgs/${logoName}`)}
                alt=""
                width={150}
                className={classes.logo}
                id="img-main-frame-base"
              />
            )) ||
              ''}
          </Link>
        </Grid>
        <Grid item md={8} sm={11} xs={11} className={classes.signupForm}>
          {children}
        </Grid>
        {!hideFooter && (
          <Grid item sm={12} xs={12}>
            <Footer />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

MainFrame.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
};

MainFrame.defaultProps = {
  hideHeader: false,
  hideFooter: false,
};
