import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextFieldPassword from '../../Common/TextFieldPassword';
import Button from '../../Common/Button';
import TitleForm from '../../Common/Title';
import SubTitle from '../../Common/SubTitle';
import { actions } from '../action';
import {
  BLUE_BUTTOM,
  WHITE,
  FONT_FAMILY,
} from '../../../constants/theme.styles';
import { setProfile } from '../../../services/signup';
import { getUser } from '../../../utils/auth';
import { APP_URL } from '../../../constants/http.constants';
import { errorMessagesUpdatePassword } from '../../../utils/MessageError';

export default function UpdatePassword() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const passwordRef = useRef(null);

  const [subTitleText, setSubTitle] = useState('SUBTITLE_UPDATE_PASSWORD');
  const [isDisabled, setIsDisabled] = useState(false);

  const marginTop = 5;
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    password: null,
    passwordConfirmation: null,
  });

  const onSubmit = async () => {
    if (
      !form.passwordConfirmation ||
      !form.passwordConfirmation ||
      form.passwordConfirmation !== form.password
    ) {
      setSubTitle('REQUIRED_FIELD');
      setError(true);
      return;
    }

    try {
      setIsDisabled(true);
      const user = getUser();
      const { _id: userId } = user;
      await setProfile(userId, {
        password: form.password,
        password_confirmation: form.passwordConfirmation,
      });

      setError(false);
      setIsDisabled(false);

      window.location.href = APP_URL;
    } catch (err) {
      setIsDisabled(false);
      setError(true);
      setSubTitle(errorMessagesUpdatePassword[err.response.data.messages[0]]);
    }
  };

  useEffect(() => {
    dispatch(actions.step(2));
    passwordRef.current.focus();
  }, [dispatch]);

  useEffect(() => {
    if (form.passwordConfirmation) {
      setError(false);
      setSubTitle('SUBTITLE_UPDATE_PASSWORD');
    }
  }, [form]);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        onSubmit();
      }}
    >
      <TitleForm error={error} id="tit-update-password">
        {formatMessage({ id: 'TITLE_UPDATE_PASSWORD' })}
      </TitleForm>
      <SubTitle error={error} id="sub-update-password">
        {subTitleText ? formatMessage({ id: subTitleText }) : null}
      </SubTitle>
      <TextFieldPassword
        id="inp-password-update-password"
        name="password"
        label={formatMessage({ id: 'PASSWORD' })}
        labelWidth={70}
        style={{ marginTop }}
        type="text"
        inputRef={passwordRef}
        autoComplete="off"
        onChange={(e) => setForm({ ...form, password: e.target.value })}
        error={(error && !form.password) || error}
        onBlur={() => {
          if (!error && form.password) {
            if (form.password.length < 6 || form.password.length > 64) {
              setError(true);
              setSubTitle('USER_VALIDATION_FAILED');
            }
          }
        }}
      />
      <TextFieldPassword
        name="passwordConfirmation"
        id="inp-new-password-update-password"
        margin="dense"
        label={formatMessage({ id: 'CONFIRM_PASSWORD' })}
        labelWidth={130}
        style={{ marginTop }}
        type="text"
        size="medium"
        autoComplete="off"
        onChange={(e) =>
          setForm({ ...form, passwordConfirmation: e.target.value })
        }
        error={
          error ||
          (form.passwordConfirmation &&
            form.passwordConfirmation !== form.password)
        }
        onBlur={() => {
          if (form.passwordConfirmation !== form.password) {
            setError(true);
            setSubTitle('CONFIRM_PASSWORD_VALIDATION_FAILED');
          }
        }}
      />
      <Grid container direction="row" justify="flex-end">
        <Grid item md={4} sm={4} xs={12}>
          <Button
            id="btn-register-update-password"
            color="primary"
            label={formatMessage({ id: 'REGISTER' })}
            style={{
              fontSize: 12,
              padding: 10,
              fontFamily: FONT_FAMILY,
              backgroundColor: BLUE_BUTTOM,
              color: WHITE,
            }}
            disabled={isDisabled}
            endIcon={
              isDisabled ? (
                <CircularProgress size={15} style={{ color: WHITE }} />
              ) : (
                ''
              )
            }
            startIcon=""
          />
        </Grid>
        <Grid style={{ minWidth: 500 }} />
      </Grid>
    </form>
  );
}
